.nav {
  * {
    font-size: $fz-14;

    @media (min-width: 1400px) {
      font-size: $fz-15;
    }
  }
  
  &-box {
    background-color: #fff;

    padding: 12px 15px;
    
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);

    @media (min-width: 1400px) {
      padding: 16px 25px;
    }
  }

  &-title {
    font-size: $fz-14;
    color: rgba($default, 0.5);

    @media (min-width: 1400px) {
      font-size: $fz-15;

    }
  }

  &-group {
    margin: 0 15px;
  }

  &-inline {
    display: flex;
    align-items: center;

    padding: 12px 0;
  }
}