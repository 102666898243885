// border-radius
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
        -o-border-radius: $radius;
            border-radius: $radius;
  }
  // .box {
  //     @include border-radius(10px);
  // }
  
  @mixin transition($property, $duration, $timing){
      -webkit-transition: $property $duration $timing;
         -moz-transition: $property $duration $timing;
          -ms-transition: $property $duration $timing;
           -o-transition: $property $duration $timing;
              transition: $property $duration $timing;
  }
  
  @mixin transform($transforms) {
         -moz-transform: $transforms;
           -o-transform: $transforms;
          -ms-transform: $transforms;
      -webkit-transform: $transforms;
              transform: $transforms;
  }
  
  // rotate
  @mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
  }
  
  // scale
  @mixin scale($scale) {
       @include transform(scale($scale));
  }
  // translate
  @mixin translate ($x, $y) {
     @include transform(translate($x, $y));
  }
  
  @mixin gradient ($start, $end, $deg: false) {
    @if $deg {
      background: #7db9e8; /* Old browsers */
      background: -moz-linear-gradient($deg , $start 0%, $end 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient($deg , $start 0%,$end 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient($deg , $start 0%,$end 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    } @else {
      background: $end; /* Old browsers */
      background: -moz-linear-gradient(top, $start -50%, $end  100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, $start -50%, $end  100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, $start -50%, $end 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
  }
  
  @mixin box-shadow($top, $left, $blur, $width, $color, $props: false) {
    @if $props {
      -webkit-box-shadow:$top $left $blur $width $color, $props;
      -moz-box-shadow:$top $left $blur $width $color, $props;
      box-shadow:$top $left $blur $width $color, $props;
    } @else {
      -webkit-box-shadow: $top $left $blur $width $color;
      -moz-box-shadow: $top $left $blur $width $color;
      box-shadow: $top $left $blur $width $color;
    }
  }

  @mixin background-opacity($color, $opacity) {
    background-color: rgba($color, $opacity);
  }

  @mixin border-opacity($bWidth, $bType, $color, $opacity) {
    background-color: $bWidth, $bType, rgba($color, $opacity);
  }

  @mixin border-color-opacity($color, $opacity) {
    border-color: rgba($color, $opacity);
  }

  @mixin color-opacity($color, $opacity) {
    color: rgba($color, $opacity);
  }


  @mixin btn-box-shadow($top, $left, $blur, $width, $color, $opacity) {
    -webkit-box-shadow: $top $left $blur $width rgba($color, $opacity);
    -moz-box-shadow: $top $left $blur $width rgba($color, $opacity);
    box-shadow: $top $left $blur $width rgba($color, $opacity);
  }

  $colors: (
    "success":      $success,
    "success-sec":  $success-sec,
    "primary":      $primary,
    "primary-sec":  $primary-sec,
    "info":         $info,
    "info-sec":     $info-sec,
    "danger":       $danger,
    "danger-sec":   $danger-sec,
    "warning":      $warning,
    "warning-sec":  $warning-sec,
    "muted":        $muted,
    "muted-sec":    $muted-sec,
    "gray":         $gray,
  );

  @each $name, $code in $colors {
    .text {
      &-#{$name} {
        color: $code !important;
      }
    }

    .btn {
      &-#{$name} {
        color: white !important;
        background-color: $code !important;
        border-color: $code !important;
        box-shadow: 0 0 0 0 $none !important;
        min-width: 100px !important;
  
        &:hover {
          will-change: color, cursor, background-color, border-color;

          cursor: pointer;
          color: white;
          border-color:  rgba($code, 0.7);
          background-color: rgba($code, 0.7);
        }
  
        &:not(:disabled),
        &:not(.disabled) {
          &:active {
            will-change: background-color, border-color, color, cursor;

            cursor: pointer;
            color: white;
            border-color: rgba($code, 0.9);
            background-color: rgba($code, 0.9);
          }
        }
  
        &:focus {
          will-change: background-color, box-shadow, color;

          color: white;
          background-color: $code !important;
          box-shadow: 0 0 0 0.2rem rgba($code, 0.5) !important;
        }
  
        &.no-hover {
          background-color: $code;
        }

        &-hover {
          &-sec {
            transition: color 0.2s ease,
                        background-color 0.2s ease !important;
            
            &:hover {
              will-change: background-color, color;

              color: $code !important;
              background-color: rgba($code, 0.1) !important;
            }
          }
        }
      }
    }
  }
  
$counter: 180;

@for $i from 0 through $counter {

  .rotate-#{$i} {
    @include rotate($i);
  }

  .pos {
    &-abs {
      &.top-#{$i} {
        top: $i + px;
      }
  
      &.left-#{$i} {
        left: $i + px;
      }
  
      &.bottom-#{$i} {
        bottom: $i + px;
      }
  
      &.right-#{$i} {
        right: $i + px;
      }
    }
  }

  .row {
    &-#{$i} {
      margin-left: -$i + px;
      margin-right: -$i + px;
    }

    &--#{$i} {
      margin-top: -$i + px;
      margin-bottom: -$i + px;
    }
  }

  .padding-#{$i} {
    padding: $i + px;

    &-right {
      padding-right: $i + px;
    }

    &-top {
      padding-top: $i + px;
    }

    &-left {
      padding-left: $i + px;
    }

    &-bottom {
      padding-bottom: $i + px;
    }

    &-lrb {
      padding: $i + px;
      padding-top: 0;
    }
  }

  .mr#{$i} {
    margin-right: $i + px !important;
  }

  .ml#{$i} {
    margin-left: $i + px !important;
  }

  .mt#{$i} {
    margin-top: $i + px !important;
  }

  .mb#{$i} {
    margin-bottom: $i + px !important;
  }

  .pb#{$i} {
    padding-bottom: $i + px !important;
  }

  .p#{$i} {
    padding: $i + px !important;
  }

  .pt#{$i} {
    padding-top: $i + px;

    &-xs {
      @media (max-width: $screen-xs-max) {
        padding-top: $i + px;
      }
    }
    &-sm {
      @media (max-width: $screen-sm-max) {
        padding-top: $i + px;
      }
    }
    &-md {
      @media (max-width: $screen-md-max) {
        padding-top: $i + px;
      }
    }

    &-lg {
      @media (max-width: $screen-lg-max) {
        padding-top: $i + px;
      }
    }
    &-xl {
      @media (max-width: $screen-xl-max) {
        padding-top: $i + px;
      }
    }
  }

  .list-item-wrapper {
    .list-#{$i} & {
      padding-top: $i + px;
      padding-bottom: $i + px;
    }
  }

  .m-#{$i} {
    margin: $i + px;

      &-r {
        margin-right: $i + px;
      }

      &-t {
        margin-top: $i + px;
      }

      &-l {
        margin-left: $i + px;
      }

      &-b {
        margin-bottom: $i + px;
      }

      &--r {
        margin-right: -$i + px;
      }

      &--t {
        margin-top: -$i + px;
      }

      &--l {
        margin-left: -$i + px;
      }

      &--b {
        margin-bottom: -$i + px;
      }
    }
  }
