.rct-group {
  .rct-group-wrapper & {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px;

      border-radius: 10px;
      background-color: #f6f6f6;

      z-index: -1;
    }
  }

  &-key {
    opacity: 0.8;
    margin-right: 5px;
    width: 100px;
    padding: 5px 10px 5px 0px;
  }

  .table-body & {
    display: flex;
    flex-direction: column;
  }

  & + .rct-group {
    .group-column & {
      margin-top: 20px;

      @media (max-width: $screen-mdpi-max) {
        margin-top: 10px;
      }
    }
  }

  &-label {
    font-size: $fz-9;
    color: #808080;

    margin-bottom: 10px;

    .rct-group-secondary & {
      font-size: $fz-11;
      color: rgba(#171818, 0.5);

      margin-bottom: 7px;
    }

    .rct-group-content & {
      .rct-group-secondary & {
        margin-bottom: 7px;
      }
    }

    .rct-group-wrapper & {
      margin-bottom: 25px;
    }

    @media (min-width: 1400px) {
      font-size: $fz-12;
    }
  }

  &-content {
    .group-primary & {
      .table-cell & {
        color: rgba(#171818, 1);
      }
    }

    .rct-group-secondary & {
      font-size: $fz-15;
    }

    .table-body & {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .placeholder {
    font-size: $fz-12;
    height: 25px;
    display: flex;
    align-items: center;
    padding: 5px;
    opacity: 0.4;
  }
}

.group {
  &-he {
    display: flex;
    align-items: baseline;

    margin-left: 5px;
    font-size: $fz-12;
    color: rgba(#171818, 0.3);

    .rct-group-content {
      font-size: $fz-12;
      
      .table-cell & {
        color: rgba(#171818, 0.3);
      }
    }

    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
  }
}