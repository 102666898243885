.popover {
  &-lg {
    width: 100vw;
    max-width: 800px;
  }

  &-footer {
    text-align: right;
    padding: 0 25px 25px;

    & > * {
      & + * {
        margin-left: 20px !important;
      }
    }
  }
}

.tooltip {
  &-content {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      opacity: 0;
      transition: opacity 0.2s ease-in;
      top: -14px;
      left: 0;
      right: 0;
      margin: auto;
      border-width: 5px;
      border-style: solid;
      width: 0px;
      height: 0px;
      border-color: #7c7c7c transparent transparent transparent;
    }

    &:hover {
      &:before {

        opacity: 1;
      }
    }
  }
}