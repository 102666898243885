.select {
  &__control {
    &:focus-within {
      .select-variant-standard & {
        box-shadow: 0 2px 0 -1px $primary;
      }
    }
    .select-size-small & {
      min-height: 20px;
    }

    .select-variant-standard & {
      border-radius: 0;
      border-width: 0;

      min-width: 90px;
    }
  }

  &__dropdown-indicator,
  &__clear-indicator {
    .select-size-small & {
      padding: 4px;
    }
    
    svg {
      .select-size-small & {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__multi-value {
    &__label {
      .select-size-small & {
        font-size: 10px;
        
        padding: 2px;
      }
    }
    
    &__remove {
      .select-size-small & {
        font-size: 10px;
        
        padding-left: 0px;
        padding-right: 0px;
      }

      svg {
        .select-size-small & {
          width: 12px;
          height: 12px;

          opacity: 0.3;
        }

        &:hover {
          .select-size-small & {
            will-change: opacity, cursor;
            
            cursor: pointer;
            opacity: 1;
          }
        }
      }
    }
  }

  &__value {
    &-container {
      .select-size-small & {
        padding: 0;
      }
    }
  }
}