.checkbox-sm {
  & + .MuiListItemText-root {
    padding-left: 0;

    .MuiTypography-root {
      font-size: $fz-14;
      font-family: $font-app;
    }
  }
}
.MuiSvgIcon-root {
  .checkbox-sm & {
    font-size: 22px;
  }

  .checkbox-xs & {
    font-size: 20px;
  }
}

.MuiCheckbox {
  &-root {
    .list-checkbox-sm &,
    .scope-dialog & {
      margin: 0;
      padding: 0 7px 0 0 !important;
    }
  }

  &-colorPrimary {
    &.MuiCheckbox {
      &-checked {
        color: $info !important;
      }
    }
  }
}
