$black:                 #000000;
$default:               #242525;
$default-sec:           #f0f0f0;
$success:               #239b4f;
$success-sec:           #baeecd;
$primary:               #064fdc;
$primary-sec:           #e6eeff;
$muted:                 #ededed;
$muted-sec:             #8b8b8b;
$danger: 		            #ff3d45;
$danger-sec:            #ffe5e3;
$warning: 		          #ff9728;
$warning-sec: 		      #fff6ce;
$info:                  #5e8ef4;
$info-sec:              #f2f9ff;
$info-thi:              #a8c8f9;
$purple:                #b047fb;
$orange:                #ff915b;
$gray:                  #8b8b8b;
$none:                  transparent;

$fz-9:               0.5625rem;
$fz-10:              0.625rem;
$fz-11:              0.7rem;
$fz-12:              0.75rem;
$fz-13:              0.8125rem;
$fz-14:              0.875rem;
$fz-15:              0.9375rem;
$fz-16:              1rem;
$fz-16x:             1.025rem;
$fz-17:              1.0625rem;
$fz-18:              1.125rem;
$fz-24:              1.5rem;
$fz-35:              2.1875rem;
$h1:                 2rem;
$h2:                 $fz-24;
$h3:                 1.25rem;
$h4:                 $fz-18;
$font-app:            "Heebo", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$font-open-sans:      "Open Sans", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$thin:                100;
$light:               300;
$regular:             400;
$medium:              500;
$semi:                600;
$bold:                700;

$up:             uppercase;
$cap:            capitalize;
$low:            lowercase;
$no:            none;

// Media

$screen-xs-max: 576px;
$screen-sm-min: 577px;
$screen-sm-max: 767px;
$screen-md-min: 768px;
$screen-md-max: 991px;
$screen-lg-min: 992px;
$screen-lg-max: 1199px;
$screen-xl-min: 1200px;
$screen-mdpi-max: 1280px;
$screen-xl-max: 1599px;
$screen-xxl-max: 1600px;