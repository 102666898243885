.MuiPaper {
  &-root {
    position: relative;
    transition: all 0.2s ease;
    box-shadow: 0 0px 0px 1px rgba(0, 0, 0, 0.1) !important;

    &.MuiPopover-paper {
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16) !important;
    }

    &:focus-within {
      will-change: box-shadow;

      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3) !important;
    }
  }
}