.header {
  height: auto;
  background-color: #fff !important;

  border-bottom: 1px solid #e6e7e7;

  box-shadow: 0 0 0 0 !important;

  &-toolbar {
    .app-header & {
      padding: 0;
    }

    @media (min-width: 600px) {
      min-height: 58px;

      .app-header & {
        min-height: 58px;
      }
    }

    @media (min-width: 1400px) {
      min-height: 70px;

      .app-header & {
        min-height: 70px;
      }
    }
  }

  &-brand {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    img {
      max-width: 128px;

      @media (min-width: 1400px) {
        max-width: 170px;
      }
    }
  }
}

.site-logo {
  padding: 15px 25px;
  border-right: 1px solid #e6e7e7;
  position: relative;

  @media (min-width: 1400px) {
    padding: 17px 25px;
  }

  .login-head & {
    border-width: 0;

    text-align: center;
  }
}

.app {
  &-header {
    position: relative;

    z-index: 10;
  }

  &-main {
    position: relative;

    z-index: 8;
  }
}