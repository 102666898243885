.drawer {
  padding: 25px 30px;

  left: 120%;
  transition: all 0.4s ease;
  
  &-fixed {
    position: fixed;
    
    top: 115px;
    bottom: 0;
    
    z-index: 20;
    background-color: #fff;
    
    box-shadow: 0 0 0 0 $none;

    &.drawer-in {
      will-change: box-shadow;
  
      box-shadow: 0px 0px 50px -30px #000;
    }
  }

  &-shadow {
    &:after {
      content: '';
      position: absolute;
      pointer-events: none;
      
      bottom: 25px;
      left: 0;
      right: 0;
      height: 25px;

      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

      z-index: 5;
    }
  }

  &.drawer-parent-scrol {
    .table-with-details & {
      width: calc(70% - 23px);
    }
  }

  .table-with-details & {
    position: absolute;

    bottom: 0px;
    right: 0px;
    top: 45px;
    width: 70%;

    background-color: #fff;
    
    box-shadow: -40px 0 30px -30px rgba(0, 0, 0, 0.3);

    z-index: 10;
  }

  &-in {
    left: 30%;
    width: 70%;
  }

  &-out {
    left: 120%;
    width: 70%;
  }

}
.group-column {
  .drawer & {
    padding: 0px 20px 25px;
    margin: 0px -18px;
  }

  .drawer-shadow & {
    padding-top: 20px;
  }
}