$colours: (
    "success":  $success,
    "primary":  $primary,
    "info":     $info,
    "error":    $danger,
    "danger":   $danger,
    "warning":  $warning,
    "muted":    $muted,
    "muted-sec":    $muted-sec,
    "gray":     $gray,
  );

.notification {
  padding: 20px;
  
  &-container {
    position: fixed;
  
    top: 20px;
    right: 20px;
    z-index: 20;

    background-color: #fff;

    border-radius: 4px;
  }

  @each $name, $code in $colours {
    &.notification {
      font-size: $fz-14;
      border-radius: 4px;

      &-#{$name} {
        color: $code !important;

        background-color: rgba($code, 0.2);
        box-shadow: 0 20px 37px 10px rgba(darken($code, 5%), 0.4);
      }
    }
  }
}