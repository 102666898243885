$colors: (
  "success":  $success,
  "primary":  $primary,
  "info":     $info,
  "danger":   $danger,
  "warning":  $warning,
  "muted":    $muted,
  "gray":     $gray,
);

@each $name, $code in $colors {
  .MuiChip-root {
    &.chip {
      &-#{$name} {
        background-color: rgba($code, 0.2);
  
        .MuiChip-label {
          color: $code;
        }
      }
    }
  }
}

.MuiChip {
  &-root {
    margin: 5px;
    
    &.chip {
      &-sm {
        font-size: $fz-12;
        height: 22px;

        @media (min-width: 1400px) {
          height: 27px;

          font-size: $fz-15;
        }
      }
    }

    @media (min-width: 1400px) {
      margin: 8px;
    }
  }

  &-label {
    display: block !important;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.chips-row {
  margin: -5px;

  .placeholder {
    padding-left: 8px;
  }

  @media (min-width: 1400px) {
    margin: -8px;
  }
}
