

.label-static {
  will-change: contents;

  line-height: 1;
  margin-right: 5px;

  @media (min-width: 1400px) {
    font-size: $fz-15;
  }

  .select-sm & {
    font-size: $fz-14;

    @media (min-width: 1400px) {
      font-size: $fz-15;
    }
  }
}

.MuiSelect {
  &-select {
    .select-xs &,
    .select-sm & {
      display: block;

      padding-top: 0;
      padding-bottom: 0;
      padding-right: 22px;

      font-size: $fz-14;
      min-height: 0;

      @media (min-width: 1400px) {
        font-size: $fz-15;
      }
    }

    .select-xs & {
      font-size: $fz-12;
  
      @media (min-width: 1400px) {
        font-size: $fz-14;
      }
    }

    & > * {
      .select-sm &,
      .select-sm & {
        display: block;
        line-height: 1;

        font-size: $fz-14;
      }
    }

    &:focus {
      .select-xs &,
      .select-sm & {
        will-change: background-color;

        background-color: $none;
      }
    }
  }
  &-selectMenu {
    .select-label-sm & {
      max-width: 200px;
    }
  }
}

.MuiInput {
  &-underline {
    &:before,
    &:after {
      .select-xs &,
      .select-sm & {
        content: none;
      }
    }
  }

  &-root {
    .select-chip & {
      font-size: $fz-14;
      color: currentColor;
    }
  }
}

.MuiSvgIcon-root {
  .select-xs &,
  .select-sm & {
    top: 0;
    bottom: 0;
    margin: auto;
    
    font-size: $fz-18;
  }

  .select-chip & {
    right: 3px;
  }

  .select-chip & {
    color: currentColor;
  }
}

.MuiListItem {
  &-default {
    &:not(:hover) {
      background-color: $none !important;
    }

    &.MuiListItem-selected {
      background-color: rgba($info, 0.2) !important;
    }
  }

  &-disabled {
    .select-overflow & {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.select {
  &-chip {
    position: relative;
    color: currentColor;

    &:before,
    &:after {
      content: '';
      position: absolute;

      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
  
      opacity: 0.1;
      background-color: currentColor;
      pointer-events: none;

      border-radius: 50px;
    }

    &:before {
      opacity: 1;
      z-index: 0;

      background-color: #fff;
      box-shadow: 0px 0px 0px 2px #fff;
    }

    &.select-default {
      &:after {
        opacity: 0.4;
        
        background-color: $none;
        box-shadow: 0px 0px 0px 1px currentColor;
      }
    }

    .MuiSelect-select {
      padding: 3px 24px 2px 10px;
      font-family: $font-app;

      @media (min-width: 1400px) {
        padding: 4px 25px 2px 15px;
      }
    }
  }

  &-overflow {
    position: relative;
  
    &:before {
      content: attr(placeholder);
      padding: 22px 25px 10px;
      display: block;
      color: #828282;
      top: 0;
      position: absolute;
      width: 100%;
      background-color: #fff;
      z-index: 1;
    }
  
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 47px;
      width: calc(100% - 15px);
      height: 10px;
  
      content: '';
      pointer-events: none;
  
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
      background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
  
    }
  
    .MuiList-root {
      position: relative;
  
      max-height: 300px;
  
      overflow: hidden;
      overflow-y: auto;
    }
  }
}