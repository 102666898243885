.MuiExpansionPanelSummary {
  &-root {
    .expansion-secondary & {
      min-height: 10px;
      padding: 0;
    }

    .collapse-container-scroll & {
      border-radius: 5px;
    }

    &.Mui-expanded {
      position: relative;

      .expansion-secondary & {
          min-height: 10px;
      }
    }

    .MuiIconButton-edgeEnd {
      .expansion-secondary & {
        padding: 5px;
        margin-right: 0;

        opacity: 0.4;
      }
    }
  }

  &-content {
    .expansion-secondary & {
      margin: 0 !important;
    }


    .sidebar-title {
      margin: 0;
      background-color: transparent;

      &-secondary {
        margin-left: 8px;
        color: rgba(#000, 0.4);
      }
    }
  }
}

.MuiExpansionPanel {
  &-root {
    .expansion-secondary & {
      box-shadow: 0 0 0 0 transparent !important;
      background-color: transparent;
      margin-bottom: 0;
    }

    &:hover {
      .MuiExpansionPanelSummary-root {
        .expansion-secondary & {
          background-color: rgba(231, 231, 231, 0.7);
        }
      }
    }

    &:before {
      .expansion-secondary & {
        display: block !important;
      }
    }

    &:first-child {
      &:before {
        .expansion-secondary & {
          display: none !important;
        }
      }
    }

    &.Mui-expanded {
      .expansion-secondary & {
        margin-top: 0;
        margin-bottom: 0;
      }

      &:before {
        .expansion-secondary & {
          opacity: 1;
        }
      }

      .MuiExpansionPanelSummary-root {
        .expansion-secondary & {
          background-color: rgba(231, 231, 231, 0);
        }

        .sidebar-title {
          span {
            .expansion-secondary & {
              color: $primary !important;
            }
          }
        }
      }
    }
  }

}

.MuiExpansionPanelDetails {
  &-root {
    .expansion-secondary & {
      padding: 0;
    }

    .tree-wrapper.enable-list {
      .expansion-secondary & {
        width: 100%;        
      }
    }

    .list {
      padding-top: 0;
      padding-bottom: 0;

      &-wrapper {
        width: 100%;
      }
    }
  }
}

.list-inline-item {
  .rct-sidebar-wrap & {
    width: 100%;
  }
}

.MuiCollapse-wrapper {
  .rct-scroll-expansion & {
    max-height: calc(100vh - 230px);
    overflow: hidden;
    overflow-y: auto;
  }

  .collapse-container-scroll & {
    padding: 12px 0;
  }

  .rct-scroll-expansion.search-expansion & {
    max-height: calc(100vh - 250px);
  }

  .expansion-scoll-lg & {
    max-height: calc(100vh - 200px);
    overflow: hidden;
    overflow-y: auto;
  }
}

.rct-sidebar-wrap {
  .rct-scroll {
    &.rct-scroll-expansion {
      &.search-expansion {
        &:after {
          bottom: 10px;
        }
      }
    }
  }
}

.expansion-secondary {
  .table & {
    margin: -11px -24px -11px -16px;
  }
  
  .MuiExpansionPanelSummary-root {
    .table & {
      padding: 11px 24px 11px 16px;
    }
  }
  
  .MuiCollapse {
    &-container {
      .collapse-container-scroll & {
        max-height: 400px;
        overflow: hidden;
        overflow-y: auto;
        background-color: #f8f9fa;
        border-radius: 10px;
      }
    }
  
    &-wrapper {
      .table & {
        position: relative;

        padding: 6px 0px 6px 16px;
        border-right: 1px solid #e3e2e2;
    
        background-color: #f8f9fb;

        &:before,
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;

          height: 5px;
          z-index: 1;
        }

        &:before {
          top: 6px;

          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f8f9fb+0,f8f9fb+100&1+0,0+100 */
          background: -moz-linear-gradient(top,  rgba(248,249,251,1) 0%, rgba(248,249,251,0) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top,  rgba(248,249,251,1) 0%,rgba(248,249,251,0) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom,  rgba(248,249,251,1) 0%,rgba(248,249,251,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f9fb', endColorstr='#00f8f9fb',GradientType=0 ); /* IE6-9 */
        }

        &:after {
          bottom: 6px;

          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f8f9fb+0,f8f9fb+100&0+0,1+100 */
          background: -moz-linear-gradient(top,  rgba(248,249,251,0) 0%, rgba(248,249,251,1) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top,  rgba(248,249,251,0) 0%,rgba(248,249,251,1) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom,  rgba(248,249,251,0) 0%,rgba(248,249,251,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f8f9fb', endColorstr='#f8f9fb',GradientType=0 ); /* IE6-9 */
        }
      }
    }

    &-wrapperInner {
      .table & {
        max-height: 50vh;
        overflow-y: auto;

        padding-right: 24px;
      }
    }
  }

  .MuiExpansionPanelSummary-expandIcon {
    .table & {
      margin: 0;
    }
  }
}

.panel {
  &-placeholder {
    color: #a0a2a3;
    padding: 5px 20px;
    font-size: 12px;
  }

  &-value {
    &-wrapper {
      &:hover {
        background-color: rgba(231, 231, 231, 0.7);
      }
    }
  }

  &-key {
    white-space: normal;

    padding-left: 20px;
  }
}

.expansion {
  &-panel {
    .collapse-container-scroll & {
      padding: 15px;
    }
  }

  &-disabled {
    cursor: default;

    * {
      pointer-events: none;
    }

    .MuiIconButton-root {
      display: none;
    }
  }
}
