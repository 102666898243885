.recharts {
  &-wrapper,
  &-surface {
    width: 100% !important;
  }
  &-responsive-container {
    min-height: 100px;

    .chart-row & {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: -15px;

      width: auto !important;
    }
  }
}

.yAxis .recharts-cartesian-axis-ticks g:last-child g {
  transform: translate(0px, 16px);
}

g.recharts-layer.recharts-cartesian-axis-tick {
  &:first-child {
    g {
      text {
        .move-first-tick & {
          transform: translate(8px, 0px);
        }
      }
    }
  }

  line {
    opacity: 0.4;
  }
}

.chart {
  &-title {
    will-change: contents;
    
    font-size: $fz-24;
    font-weight: $semi;

    font-family: $font-open-sans;

    @media (min-width: 1400px) {
      font-size: $fz-35;
    }
  }

  &-wrapper {
    z-index: 1;

    &.chart-active,
    &:hover {
      will-change: transform, box-shadow, z-index, cursor;

      cursor: pointer;
      transform: scale(1.06);
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);

      z-index: 2;
      
      & * {
        cursor: pointer;
      }
    }

    &.cursor-default {
      &:hover {
        will-change: cursor;
        
        cursor: default;
        
        & * {
          cursor: default;
        }
      }
    }

    &.chart {
      &-disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
}