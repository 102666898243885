.MuiSvgIcon-root {
  .header & {
    font-size: $fz-18;
  }

  .MuiTableBody-root td:first-child & {
    .table & {
      box-shadow: inset 0px 0px 0px 10px white;
      border-radius: 4px;
    }
  }

  &.icon-abs {
    position: absolute;

    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    fill: #848484;
  }
}

.MuiIconButton-root {
  .MuiPaper-root & {
    padding: 4px;
    border-radius: 4px;
    margin: 4px;
  }
}