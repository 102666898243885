.MuiTablePagination-root {
  .table-container & {
    width: 100%;
  }
}

.MuiTable-root {
  .table-sticky & {
    .table-border-right & {
      box-shadow: inset -1px 0px 0px 0px #e2e2e2;
    }
  }
}

.layout {
  &-fixed {
    table-layout: fixed;
  }
}

.cell {
  &-messageId,
  &-message {
    width: 30%;
  }

  &-messageId {
    word-break: break-word;
  }

  &-origin,
  &-operationId {
    width: 10%;
  }

  &-operationId {
    min-width: 100px;
  }

  &-submissionTimestamp {
    width: 10%;
  }
}

.hover-pointer {
  &:hover {
    will-change: cursor, background-color;
    
    cursor: pointer;
    background-color: rgba(#000000, 0.03);
  }
}

.table {
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;

  &.no-shadow {
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0) !important;
  }

  [colspan] {
    border-bottom: 1px solid #e3e2e2;
  }

  &-with-details {
    overflow: hidden;
  }

  td {
    box-shadow: 0px 0px 0px 0px $none;
    background-color: $none;
    transition: all 0.2s ease;
  }

  &.table-details-open {
    min-height: 750px;
  }

  .tooltip {
    &-inner-wrapper {
      padding: 11px;
      margin: -11px;
    }

    &-title {
      margin-top: 5px;
      color: white;
      display: flex;
    }
  }

  &-wrapper {
    .table-with-details & {
      position: relative;
  
      overflow: hidden;
      overflow-y: auto;
    }
  }

  &-sticky {
    overflow-y: auto;
    max-height: 700px;
  }

  &-title {
    &-extra {
      font-size: $fz-12;
      
      margin-right: 10px;

      @media (min-width: 1400px) {
        font-size: $fz-15;
      }
    }
  }

  &-cell {
    padding: 10px;
    font-size: $fz-14;

    border-top: 1px solid #000;

    .cell-lg-first & {
      &:hover {
        will-change: cursor;

        cursor: default;
      }
    }

    &-active {
      background-color: rgba($primary, 0.05) !important;
    }
    .tr-active & {
      background-color: rgba($primary, 0.1);
    }
    
    &:hover {
      will-change: cursor, background-color;
    
      cursor: pointer;
    }

    .cell-lg-first & {
      width: 100px;
    }

    &:first-child {
      .cell-lg-first & {
        width: 400px;
      }
    }

    &-hover {
      cursor: pointer;
    }

    &-label {
      user-select: none;
      
      .table-cell-active & {
        color: $primary;
      }
    }

    @media (min-width: 1400px) {
      font-size: $fz-12;
    }

    .rct-group-content {
      font-size: $fz-14;
      color: rgba(#000, 0.6);

      @media (max-width: 1200px) {
        font-size: $fz-12;
        color: rgba(#000, 0.6);
      }
    }


    thead & {
      font-size: $fz-12;
      border-top: 0px solid #000;

      font-weight: $semi;
    }
  }

  &-row {
    &-active {
      td {
        border-color: $info !important;
        
        background-color: #e7eefc;
        box-shadow: 0px -1px 0px 0px $info;
      }
    }
  }

  &-toolbar {
    &-secondary {
      background-color: #fbfcfc;
      position: relative;

      min-height: 45px !important;

      z-index: 3;

      &:before {
        position: absolute;
        content: '';

        bottom: 0;
        left: 0;
        right: 0px;
        height: 1px;

        .table-border-right & {
          right: 22px;
        }

        background-color: #e2e2e2;
      }
    }
  }

  &-cell {
    &-sm {
      .MuiTableCell {
        &-paddingCheckbox {
          & + .MuiTableCell-body {
            padding-left: 0;
          }
        }

        &-body {
          & + .MuiTableCell-body {
            padding-right: 25px;
          }
        }
      }
    }
    
    &-icon {
        width: 18px;
        height: 18px;
        position: relative;

        margin-left: 2px;

        .MuiSvgIcon-root {
          font-size: $fz-14;
          position: absolute;
          
          top: 0;
          left: 0;
        }
    }

    &-hover {
      &:hover {
        will-change: cursor;

        cursor: pointer !important;
      }
    }
  }
}

.MuiTableCell {
  &-root {
    border-color: #e2e2e2 !important;

    .table-cell-md & {
      padding-top: 11px;
      padding-bottom: 11px;
      padding-right: 24px;
      
      line-height: 1.5;

      @media (max-width: $screen-mdpi-max) {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 15px;
      }

    }
    
    .cell-lg-first & {
      padding-right: 11px;
    }

    &:last-child {
      thead & {
        .table-sticky & {
          box-shadow: 0px 1px 0px 0px #e2e2e2, inset -1px 0px 0px 0px #e2e2e2;
        }
      }
    }

    thead & {
      .table-sticky & {
        position: sticky;
        top: 0;
        
        background-color: #fff;
        box-shadow: 0px 1px 0px 0px #e2e2e2;
        
        z-index: 2;
      }
    }
    
    tfoot & {
      .table-sticky & {
        position: sticky;
        bottom: 0px;

        background-color: #fff;
        box-shadow: 0px -1px 0px 0px #e2e2e2;
      }
    }
  }

  &-body {
    .table & {
      @media (min-width: 1400px) {
        font-size: $fz-15;
      }
    }
  }
}

[colspan] {
  .MuiTableFooter-root & {
    border-bottom: 0;
  }
}

.MuiTableRow-root {
  &.Mui-selected,
  &.MuiTableRow-selected {
    .table & {
      background-color: rgba($primary, 0.025);
    }

    &:hover {
      .table & {
        background-color: rgba(#000, 0.025);
      }
    }
  }
}

.tr {
  &-danger {
    &-sec {
      background-color: rgba($danger, 0.07);
    }
  }

  &-critical {
    background-color: rgba(255, 61, 69, 0.07);
  }
}

.td {
  &-icon {
    display: flex;

    margin-left: auto;
  }
}

.tlf {
  table-layout: fixed;
}

.w50 {
  width: 50%;
}

td,
th {
  &:first-child {
    .cell-lg-first & {
      border-right: 1px solid #e3e2e2;
    }
  }
}

.total-hide .MuiTablePagination-selectRoot + .MuiTablePagination-caption {
  width: 0;
  height: 0;
  overflow: hidden;
  margin-right: -50px;
  pointer-events: none;
}

.MuiTooltip {
  &-tooltip {
    .MuiTooltip-popper & {
      background-color: #7d7d7d;
      position: relative;
    }
  }

  &-tooltipPlacementTop {
    &:after {
      position: absolute;
      content: '';

      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
