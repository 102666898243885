html,
body,
#root {
  height: 100%;
}

html {
  scroll-behavior: smooth;

  @media (max-width: $screen-mdpi-max) {
    font-size: $fz-13;
  }
}

body{
  color: $default;
  font-family: $font-app;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f8f9fa;
}

#root {
  display: flex;
  flex-direction: column;
}

.block {
  width: 100% !important;
}

.dt {
  display: table !important;
}
.dtr {
  display: table-row;
}

.dthg {
  display: table-header-group;
}

.dtrg {
  display: table-row-group;
}

.dth,
.dtc {
  display: table-cell;

  .dtr:hover & {
    .th & {
      background-color: rgb(230, 230, 230);
    }
  }
}

.disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
  
  &-loading {
    opacity: 0.3 !important;
    pointer-events: none !important;
  }
}

.hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.dib {
  display: inline-block !important;
}

.title {
  &-cutted {
    position: relative;

    .statistic & {
      position: inherit;
    }

    &:before {
      content: attr(title);
      position: absolute;
      pointer-events: none;
      left: 0;
      right: 0;
      
      word-break: break-word;
      
      background-color: #fff;
      border-radius: 5px;
      opacity: 0;
      
      padding: 12px;

      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      transition: opacity 0.2s ease;
    }

    &:hover {
      &:before {
        will-change: opacity;

        opacity: 1;
      }
    }
  }

  &-trim {
    display: block;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    line-height: initial;

    &-md {
      max-width: 200px;
    }
  }
}

.no {
  &-events {
    pointer-events: none !important;

    &-inside {
      & > * {
        pointer-events: none !important;
      }
    }
  }
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mtb {
  &-sm {
    margin: 20px 0px 20px 0px;
  }

  &-md {
    margin: 30px 0px 30px 0px;

    &.MuiDivider-root {
      margin: 30px 0px 30px 0px;
    }
  }

  &-lg {
    margin: 40px 0px 30px 0px;
    
    &.MuiDivider-root {
      margin: 40px 0px 30px 0px;
    }
  }
}

.max {
  &-w {
    &-600 {
      max-width: 600px;
    }
  }
}

.dotted {
  background-color: transparent !important;
  border-bottom: dashed 1px #e2e2e2 !important;
}

.events {
  &-no {
    pointer-events: none;
  }

  &-all {
    pointer-events: all;
  }
}

.extra-actions-wrapper {
  &.disabled {
    .MuiInput-root {
      pointer-events: none;
    }
  }
}

.pos {
  &-rel {
    position: relative;
  }

  &-abs {
    position: absolute !important;

    &.full {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 5;

      margin: auto;
    }

    &.top {
      top: 0;

      @media (min-width: 1400px) {
        .container-fluid & {
          top: 25px;
        }
      }

      @media (max-width: 1399px) {
        .container-fluid & {
          top: 15px;
        }
      }
    }

    &.left {
      left: 0;

      @media (min-width: 1400px) {
        .container-fluid & {
          left: 25px;
        }
      }

      @media (max-width: 1399px) {
        .container-fluid & {
          left: 15px;
        }
      }
    }

    &.bottom {
      bottom: 0;

      @media (min-width: 1400px) {
        .container-fluid & {
          bottom: 25px;
        }
      }

      @media (max-width: 1399px) {
        .container-fluid & {
          bottom: 15px;
        }
      }
    }

    &.right {
      right: 0;

      @media (min-width: 1400px) {
        .container-fluid & {
          right: 25px;
        }
      }

      @media (max-width: 1399px) {
        .container-fluid & {
          right: 15px;
        }
      }
    }
  }
}

.app {
  &-container {
    display: flex;
    flex-direction: column;

    height: 100%;
  }

  &-main {
    flex: 1;

    overflow: hidden;
    overflow-y: auto;
  }
}

.container {
  &-sticky {
    position: sticky;
    top: 0px;
    z-index: 10;
    background: #f8f9f9;
    box-shadow: 0px 1px 0px 0 #e3e2e2, 0px -5px 0px 6px #f8f9f9;
    padding: 10px 0;
  }

  &-fluid {
    width: 100%;
    padding: 15px;
    position: relative;

    @media (min-width: 1400px) {
      padding: 25px;
    }

    &.pt0 {
      padding-top: 0;
    }

    &.pb0 {
      padding-bottom: 0;
    }
  }
}

.scroll {
  &-x {
    overflow: hidden;
    overflow-x: auto;
  }

  &-y {
    overflow: hidden;
    overflow-y: auto;
  }

  &-70 {
    height: 70vh;
  }

  &-fade {
    &-before,
    &-after {
      position: relative;
      
      &:before,
      &:after {
        content: '';
        position: absolute;
        
        height: 15px;
        width: 100%;
        
        left: 0;
        right: 0;
        
        z-index: 10;
        pointer-events: none;
      }
    }

    &-before {
      &:before {
        top: 5px;

        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f7f8f8+0,f7f8f8+100&1+0,0+100 */
        background: -moz-linear-gradient(top,  rgba(247,248,248,1) 0%, rgba(247,248,248,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(247,248,248,1) 0%,rgba(247,248,248,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(247,248,248,1) 0%,rgba(247,248,248,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f8f8', endColorstr='#00f7f8f8',GradientType=0 ); /* IE6-9 */
      }
    }

    &-after {
      &:after {
        bottom: 22px;

        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f7f8f8+0,f7f8f8+100&0+0,1+100 */
        background: -moz-linear-gradient(top,  rgba(247,248,248,0) 0%, rgba(247,248,248,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(247,248,248,0) 0%,rgba(247,248,248,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(247,248,248,0) 0%,rgba(247,248,248,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f7f8f8', endColorstr='#f7f8f8',GradientType=0 ); /* IE6-9 */
      }
    }
  }
}

.rct-scroll {
  & > * {
    &:first-child {
      .scope-dialog & {
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }
  }
}

.min {
  &-h {
    &-lg {
      min-height: 170px;
    }
  }
}

.w {
  &-100 {
    width: 100%;
  }
}

.h {
  &-100 {
    height: 100%;
  }
}

.hover {
  &-pointer {
    background-color: transparent;
    
    transition: all 0.2s ease;

    &:hover {
      will-change: cursor;
      
      cursor: pointer;
    }
  }
}

.login {
  &-body {
    width: 100%;
    max-width: 300px;
  }

  &-wrapper {
    position: relative;

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    &:after {
      background-image: url('../img/bg_or.jpeg');
      background-size: cover;
      background-position: center;

      opacity: 0.7;
    }

    &:before {
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#28aae1+0,287cb0+21,d31142+65,ffffff+100 */
      background: rgb(40,170,225); /* Old browsers */
      background: -moz-linear-gradient(45deg,  rgba(40,170,225,1) 0%, rgba(40,124,176,1) 21%, rgba(211,17,66,1) 65%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(45deg,  rgba(40,170,225,1) 0%,rgba(40,124,176,1) 21%,rgba(211,17,66,1) 65%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(45deg,  rgba(40,170,225,1) 0%,rgba(40,124,176,1) 21%,rgba(211,17,66,1) 65%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28aae1', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

      opacity: 0.3;
      z-index: 1;
    }
  }

  &-body {
    position: relative;
    z-index: 5;
  };
}

.MuiFormControl-root {
  &.direction-row {
    flex-direction: row;
  }
}

::-webkit-scrollbar {
  width: 16px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f8f9fa;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e6e6e6;
  box-shadow: inset 0px 0px 0px 6px #f8f9fa;
}

.scroll-white::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
}

.scroll-white::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e6e6e6;
  box-shadow: inset 0px 0px 0px 6px #ffffff;
}

.transfer-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e7e7e7;
  box-shadow: inset 0px 0px 0px 6px #f6f6f6;
}

.transfer-list::-webkit-scrollbar-track {
  background-color: #f6f6f6;
  border-radius: 10px;
}

.MuiPaper-root .MuiList-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e7e7e7;
  box-shadow: inset 0px 0px 0px 6px #fff;
}

.MuiPaper-root .MuiList-root::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
}

.table .table-sticky::-webkit-scrollbar {
  width: 22px;
  height: 22px;
}

.table .table-sticky::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: #e6e6e6;
  box-shadow: inset 0px 0px 0px 9px #fbfcfc;
}

.table .table-sticky::-webkit-scrollbar-track {
  background-color: #fbfcfc;
  border-radius: 0px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #c3c3c3;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #c3c3c3;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #c3c3c3;
}
:-moz-placeholder { /* Firefox 18- */
  color: #c3c3c3;
}
