.MuiDivider-root {
  .login-body & {
    margin-top: 10px;
    margin-bottom: 20px;

    margin-left: auto;
    margin-right: auto;

    width: 80%;
  }
}