.loader {
  &-abs {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    z-index: 10;

    border-radius: 2px;
    background-color: rgba(#fff, 0.85);
  }
}