.resetBtn {
  padding: 0;
  background-color: transparent;
  box-shadow: 0 0 0 0 transparent;
  line-height: 1;
  color: $default;
  text-transform: none;
  font-weight: normal;
}

.noActionsEvents {
  background-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}


.btn {
  &-value {
    position: relative;

    padding-right: 22px;

    .nav-group * {
      @media (min-width: 1400px) {
        font-size: $fz-15;
      }
    }
  }

  &-group {
    button {
      & + button {
        margin-left: 5px;
      }
    }
  }

  &-divider {
    margin-left: 10px;
  
    &:before {
      border-left: 1px solid #e2e2e2;
      display: block;
      height: 20px;
      content: '';
      position: relative;
      left: 0px;
    }
  }

  &-muted {
    color: $default !important;
  }

  &-xs {
    min-width: 20px;
    font-size: $fz-12 !important;
    padding: 0 !important;
    
    span {
      span {
        font-size: $fz-12;
        padding: 3px 8px;
      };
    }
  }

  &-sm {
    padding: 2px 6px !important;

    span {
      svg {
        font-size: $fz-14;

        & + span {
          margin-left: 4px;
        }
      }
      
    }
  }

  &-clear {
    @extend .resetBtn;
    
    &:hover {
      will-change: background-color, box-shadow;

      @extend .noActionsEvents;

      &.MuiButton-root {
        @extend .noActionsEvents;
      }
    }

    &.MuiButton-root {
      @extend .resetBtn;
    }
  }

  &-title {
    .nav-group * {
      @media (min-width: 1400px) {
        font-size: $fz-15;
      }
    }

    & + .btn-value {
      margin-left: 5px;
    }
  }
}

[class*='btn-'],
[class^='btn-'] {
  &.item-disabled {
    color: #bbbcbc !important;
    pointer-events: none;
  }
}

.events-value {
  pointer-events: none !important;

  .btn-value {
    pointer-events: all !important;
  }
}

.MuiButton {
  &-label {
    text-transform: none;
    font-weight: normal;
  }
}

.MuiButtonBase-root {
  &.Mui-disabled {
    .popover-footer & {
      opacity: 0.3;
    }
  
    &.enabled-content {
      color: initial;
      
      .MuiFormControl-root {
        pointer-events: all;

        text-transform: none;

      }
    }
  }
}