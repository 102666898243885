.rct-block {
  position: relative;
  border-radius: 5px;
  border: none;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  
  padding: 12px;
  display: flex;
  flex-direction: column;

  transition: all 0.2s ease-in-out 0s;

  &-sm {
    padding: 7px 6px 7px 12px;
  }

  &.shadow-lg {
    box-shadow: 0 20px 37px 10px rgba(40, 43, 67, 0.3);
  }

  &-wrapper {
    padding: 10px;
    max-width: 20%;
    width: 100%;

    @media (min-width: 1400px) {
      padding: 12.5px;
    }
  }

  &-row {
    margin: -10px;

    @media (min-width: 1400px) {
      margin: -12.5px;
    }
  }

  &-title {
    & + .rct-block-content {
      margin-top: 5px;
    }
  }

  &-content {
    & + .recharts-responsive-container,
    & + .recharts-container {
      margin-top: 25px;
    }
  }

  & + .rct-block {
    .table-body & {
      margin-top: 15px;
    }
  }
};
