.toggler {
  &-secondary {
    .label-static {
      font-size: $fz-12;

      color: rgba($default, 0.7);
    }

    .MuiSelect-select {
      color: $info;
    }
  
    .MuiToggleButton {
      &-label {
        text-transform: none;
      }

      &-root {
        color: $info;
        
        height: auto;
        border-color: rgba($info, 0.2);

        &:hover {
          will-change: background-color;
          
          background-color: rgba($info, 0.3);
        }
        
        &.Mui {
          &-selected {
            color: $info;
            background-color: rgba($info, 0.2);

            &:hover {
              will-change: background-color;

              background-color: rgba($info, 0.3);
            }
          }
        }
      }
    }
  }
}