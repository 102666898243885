.pagination {
  &-wrapper {
    display: flex;
    align-content: center;
  }

  &-footer {
    padding: 20px 0;
  }

  &-sm {
    .MuiTablePagination {
      &-root {
        padding: 0;
  
        border-bottom: 0;
  
        .MuiIconButton-root {
          padding: 5px;
        }
      }

      &-toolbar {
        padding: 0;

        height: auto;
        min-height: 50px;
      }

      &-select {
        padding-right: 20px;
      }
    }
  }

  &-sticky {
    position: sticky;
    top: 0px;
    z-index: 10;
    background: #f8f9f9;
    margin-bottom: 10px;
    box-shadow: 0px 0px 5px 5px #f8f9fa;
  }
}

.MuiTablePagination {
  &-select {
    .table-wrapper & {
      padding-right: 20px;
    }
  }
}

.select-rows {
  .last-end & {
    position: absolute;
    right: 0;
  }
}