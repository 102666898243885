.rct {
  &-label {
    will-change: contents;
  
    display: flex;
    align-items: center;

    &-inner {
      display: flex;
      align-items: center;

      transition: all 0.2s ease;

      &.secondary {
        color: rgba($default, 0.6);
      }

      .rct-title-md & {
        font-size: $fz-24;
      }

      .rct-title-lg & {
        font-size: $fz-35;
      }

      .first-letter-up & {
        display: block !important;
      }

      &:first-letter {
        .first-letter-up & {
          display: block;
          text-transform: uppercase;
        }
      }

      &:hover {
        will-change: color, opacity, cursor;
        
        cursor: pointer;
        opacity: 0.8;
        color: $primary;

        &.events-no {
          will-change: color, opacity;

          opacity: 1;
          color: inherit;
          
          .rct-title-prefix {
            will-change: opacity, color, cursor;

            cursor: pointer;
            opacity: 0.8;
            color: $primary;
          }
        }

      }
    }

    .first-letter-up & {
      display: block !important;
    }

    &:first-letter {
      .first-letter-up & {
        display: block;
        text-transform: uppercase;
      }
    }

    .title-secondary & {
      font-size: $fz-24;

      align-items: baseline;
    }

    .rct-block & {
      text-transform: uppercase;
  
      font-weight: $bold;
      font-size: $fz-12;

      @media (min-width: 1400px) {
        font-size: $fz-15;

        margin-bottom: 8px;
      }
    }
    
    &-secondary {
      font-size: $fz-12;
      color: rgba(#171818, 0.6);

      &-single {
        margin-left: 0;
      }

      .title-secondary & {
        font-size: $fz-12;
      }

      .first-letter-up & {
        float: right;
        top: 9px;
        position: relative;
      }

      .rct-title-lg & {
        font-size: $fz-15;
      }

      @media (min-width: 1400px) {
        font-size: $fz-15;
      }

      font-weight: normal;
      text-transform: none;

      margin-left: 10px;

      .MuiSvgIcon-root {
        font-size: 8px;

        margin-left: 5px;
      }
    }
  }

  &-title {
    &-content-secondary {
      margin: 5px 0px -12px 25px;
      font-size: $fz-12;
      color: #a4a4a4;
    }
  
    &-wrapper {
      .drawer-shadow & {
        position: relative;

        margin-bottom: 0;
      }

      &:after {
        .drawer-shadow & {
          content: '';
          position: absolute;
          pointer-events: none;

          bottom: -25px;
          left: -30px;
          right: -30px;
          height: 25px;

          z-index: 5;

          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+26,ffffff+100&1+24,0+100 */
          background: -moz-linear-gradient(top,  rgba(255,255,255,1) 24%, rgba(255,255,255,0.97) 26%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 24%,rgba(255,255,255,0.97) 26%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom,  rgba(255,255,255,1) 24%,rgba(255,255,255,0.97) 26%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
        }
      }

      & + .toolbar-content-wrapper {
        margin-left: 25px;
      }

    }
  }
}

.title-secondary {
  margin-bottom: 25px;
}