// How to use https://css-tricks.com/line-clampin/

.clamp {
  &-module {
    will-change: contents;
  }

  &-overflow {
    --max-lines: 2;
    position: relative;
    max-height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
    padding-right: 1rem;

    &:before {
      position: absolute;
      content: "...";
      bottom: 0;
      right: 0;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 1rem;
      height: 1rem;
      background: white;
    }
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
}

.fade {
  position: relative;
  height: 25px;
  word-break: break-word;
  line-height: 1;
  overflow: hidden;

  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: -3px;
    right: 0;
    width: 20%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
  }

  @media (min-width: 1400px) {
    height: 29px;
  }
}

.last-line {
  height: 1.6em;
  text-overflow: -o-ellipsis-lastline;
  word-break: break-word;
  line-height: 1;
}

.ftellipsis {
  height: 3.6em;
}
