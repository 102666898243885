.transfer {
  &-wrapper {
    margin: 0 -20px;
    padding-top: 20px;

    .MuiPaper-root & {
      height: 100vh;
      max-height: 365px;

      display: flex;
      justify-content: space-between;
    }
  }

  &-footer {
    text-align: right;
    padding-top: 20px;

    & > * {
      & + * {
        margin-left: 20px !important;
      }
    }
  }

  &-source,
  &-target {
    &-list {
      padding: 5px 15px;
      border-radius: 6px;
      border: 1px solid #f6f6f6;
      background-color: #f6f6f6;

      height: 100%;
    }
  }

  &-target {
    &-list {
      border-color: $info-thi;
      background-color: $info-sec;
    }
  }

  &-list {
    margin-bottom: 0;
    overflow-y: auto;
    list-style: none;
    
    &-item {
      display: flex;
      align-items: center;
      
      padding: 8px 5px;
      margin: 0px -5px;

      transition: all 0.2s ease;

      &:hover {
        will-change: background-color;

        background-color: #e7e7e7;
      }

      .wrapper {
        width: 100%;
        padding-left: 30px;
        position: relative;
        font-size: $fz-14;

        .MuiIconButton-root {
          position: absolute;
          left: -2px;
          top: -5px;
          margin: 0;
          font-size: $fz-16;
        }
      }

      &-disabled {
        cursor: not-allowed;
      }

      &-sublabel {
        font-size: 11px;
        color: #a2a2a2;
        display: inline-block;
      }

      &-label {
        .transfer-list-item-disabled & {
          opacity: 0.3;
        }

        &-container {
          margin-right: 8px;
        }
      }
    }
  }
}

.target-list,
.source-list {
  &-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 20px;
    margin-right: 15px;
  }

  &-title {
    margin-bottom: 12px;
    font-size: $fz-14;
    
    display: flex;
    align-items: center;
    
    & > span {
      font-weight: $semi;
      margin-right: 3px;
    }

    .MuiButtonBase-root {
      margin-left: auto !important;
    }

    .MuiButton-root {
      margin: -7px 0px;   
    }
  }
}

.target-list {
  &-wrapper {
    margin-left: 15px;
    margin-right: 20px;
  }
}