.h1 {
  font-size: $h1;
}

.h2 {
  font-size: $h2;
}

.h3 {
  font-size: $h3;
}

.h4 {
  font-size: $h4;
}

.text {
  &-nowrap {
    white-space: nowrap;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-critical {
    color: $danger;
  }

  &-small {
    font-size: 80%;
    
    & > * {
      font-size: 80% !important;
    }
  }

  &-italic {
    font-style: italic;
  }
}

.first {
  &-letter {
    &-up {
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

$transforms: (
  "up":    $up,
  "cap":   $cap,
  "low":   $low,
  "no":   $no,
);

@each $name, $code in $transforms {
  .text {
    &-#{$name} {
      text-transform: $code;
    }
  }
}

$weights: (
  "thin":     $thin,
  "light":    $light,
  "regular":  $regular,
  "medium":   $medium,
  "semi":     $semi,
  "bold":     $bold,
);

@each $name, $code in $weights {
  .text {
    &-#{$name} {
      font-weight: $code;
    }
  }
}

$headers: 6;

.lh {
  &-sm {
    line-height: 1.2;
  }

  &-md {
    line-height: 1.4;
  }
}

@for $i from 0 through $headers {
  .zi-#{$i} {
    z-index: $i;
  }

  .lh-#{$i} {
    line-height: $i;
  }
}

$sizes: 60;

@for $i from 0 through $sizes {
  .fz-#{$i} {
    font-size: $i + px !important;
  }
}
