.section {
  &-block {
    position: relative;
  }

  &-loader {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(248, 249, 250, 0.86);

    & > * {
      width: 60%;
    }
  }
}