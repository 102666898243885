.statistic {
  position: relative;

  &-title {
    font-size: $fz-12;
    color: rgba(#171818, 0.5);
    padding-right: 5px;

    @media (min-width: 1400px) {
      font-size: $fz-14;

      line-height: 21px;
    }
  }

  &-suffix {
    display: flex;
    align-items: center;

    margin-left: 2px;
  }

  &-value {
    &-container {
      display: flex;
      align-items: center;

      font-size: $fz-10;
      font-style: italic;
      font-weight: $light;
      
      .MuiSvgIcon-root {
        stroke-width: 1px;
        stroke: currentColor;

        font-size: $fz-11;
      }
    }
  }
}