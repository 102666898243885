.MuiBadge {
  &-colorPrimary {
    .badge-tr & {
      background-color: $info;
    }
  }

  &-badge {
    .badge-sm & {
      width: 15px;
      height: 15px;
      min-width: 0;

      font-size: $fz-10;

      padding: 0;
    }
  }
}

.rct {
  &-badge {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: $bold;
    font-size: $fz-14;

    width: auto;
    min-width: 25px;
    height: 25px;
    border-radius: 2px;

    margin: 5px;
    padding: 0 8px;

    background-color: #f8f9fa;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      border-radius: 2px;

      background-color: currentColor;
      opacity: 0.05;

      z-index: 1;
    }

    &:before {
      position: absolute;
      content: '';
      opacity: 0;
      transition: opacity 0.3s ease;
      top: -14px;
      left: 0;
      right: 0;
      margin: auto;
      border-width: 5px;
      border-style: solid;
      width: 0px;
      height: 0px;
      border-color: #7c7c7c transparent transparent transparent;
    }

    &:hover {
      &:before {

        opacity: 1;
      }
    }

    &-value {
      will-change: contents;

      position: relative;

      z-index: 2;
    }

    @media (min-width: 1400px) {
      font-size: $fz-18;

      min-width: 36px;
      height: 36px;

      margin: 11px;
      padding: 0 12px;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
  
      margin: -5px;

      @media (min-width: 1400px) {
        margin: -12px;
      }
    }

    &-logo {
      font-size: 8px;
      text-align: center;
      color: #989999;
      text-transform: uppercase;
      letter-spacing: 7px;
      font-weight: 700;
      max-width: 170px;
      overflow: hidden;
      display: block;
      white-space: nowrap;
      padding-top: 3px;
      margin-bottom: -5px;
      
      @media (max-width: 1399px) {
        font-size: 7px;
        letter-spacing: 4.7px;

        max-width: 128px;
      }
    }
  }
}