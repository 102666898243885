.list {
  margin-bottom: 0;
  list-style: none;

  &-horizontal {
    display: flex;

    margin: 0 -10px;
    
    &.list-lg {
      margin: 0 -20px;
    }
  }

  &-checkbox {
    padding: 12px !important;

    &-title {
      padding-left: 0 !important;
    }

    &-wrapper {
      padding: 0 !important;
    }

    &-item {
      .list-checkbox-sm & {
        padding: 0;
      }
    }
  }

  &-wrapper {
    .rct-subheader & {
      width: 100%;
    }
  }

  &-item {
    &-sku,
    &-price {
      .flex-grid-calc-90 & {
        flex: 0 1 90px !important;
      }
    }

    &-badge {
      &-wrapper {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-disabled {
      opacity: 0.3;
      pointer-events: none;

      & * {
        pointer-events: none;
      }
    }

    &-wrapper {
      .rct-subheader & {
        width: 100%;
        max-width: 200px;
      }
    }

    &-link {
      color: $default;
      font-size: 12px;
      padding: 15px 10px;
      transition: all 0.2s ease;
      background-color: #fff;

      @media (min-width: 1400px) {
        font-size: $fz-15;
      }
      
      .list-lg & {
        padding: 15px 20px;
      }

      .flex & {
        display: flex;
        align-items: center;

        height: 100%;
      }

      .list-sm & {
        padding: 15px;
      }

      .list-tab & {
        text-align: center;
        justify-content: center;
      }

      &-active {
        pointer-events: none;
        color: $info;

        &:focus {
          will-change: color, pointer-events;

          pointer-events: none;
          color: $info;
        }

        .list-tab & {
          background-color: #f3f3f3;
        }
      }

      &:hover {
        will-change: color;

        color: $primary;

        .list-tab & {
          will-change: background-color, color;

          color: $default;
          background-color: #f7f7f7;
        }
      }
    }

    &.dropdown {
      &:after {
        position: absolute;
        content: "\F2FB";
        font-family: Material-Design-Iconic-Font;
        right: 1.5rem;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out;
        transform: rotate(0deg);
      }
    }

    &.item-active {
      &:after {
        transform: rotate(90deg);
      }
    }
  }

  &-inline {
    & + .list-wrapper {
      margin-left: 20px;
    }
  }
}

.MuiListItemText-secondary {
  .crop-secondary & {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 400px;
  }
}

[class^='ti-'],
[class*='ti-'],
[class^='icon-'],
[class*='icon-'] {
  .list-wrapper & {
    margin-right: 8px;
  }
}

.lists {
  &-group {
    padding: 0 24px;
  }
}

.language {
  &-icon {
    width: 4rem;
  
    img {
      .list-item-clear & {
        margin-right: 5px !important;
      }
    }
  
    .list-item-clear & {
      font-size: 12px;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      border: 0;
      box-shadow: 0 0 0 0 transparent;
    }
  
    &:hover {
      .list-item-clear & {
        will-change: background-color;

        background-color: #f7f7f7;
      }
    }
  }

  &-dropdown {
    .dropdown-menu {
      width: 290px;
    }
  }
}
