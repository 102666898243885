.react-checkbox-tree {
  ol {
    list-style: none;

    ol {
      padding-left: 18px;

      .rct-node {
        position: relative;
      
        &:before {
          position: absolute;
          content: '';
          width: 10px;
          height: 15px;
          border-left: 1px dotted rgb(189, 189, 189);
          border-bottom: 1px dotted rgb(189, 189, 189);
          top: -2px;
          left: -6px;
          margin: auto;
        }

        & + .rct-node {
          &:before {
            top: -10px;
            height: 22px;
          }
        }
      }
    }
  }

  .sidebar-group & {
    display: block;
  }

  .rct-checkbox {
    .hide-checkbox & {
      display: none;
    }

    & + .rct-node-icon {
      display: none;
    }
  }
}

.rct {
  &-collapse {
    .rct-icon {
      &:empty {
        .hide-checkbox & {
          display: none;
        }
      }
    }
  }
  
  &-title {
    .rct-sidebar & {
      padding: 0;
      font-size: 14px;
    }
  }
}
 

[class^='MuiCheckbox-root']:not([class^='MuiCheckbox-checked']),
[class*='MuiCheckbox-root']:not([class*='MuiCheckbox-checked']) {
  .disable-list & {
    opacity: 0.2;
    pointer-events: none;
  }

  & * {
    .disable-list & {
      pointer-events: none !important;
    }
  }
}

[class*='MuiListItem-root'] {
  .disable-list & {
    pointer-events: none;
  }

  .transfer-list-container & {
    transition: background-color 0s ease;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid #f5f5f5;
  }

  &:hover {
    .transfer-list-container & {
      background-color: rgb(240, 240, 240);
      background-color: #f7f9fa;
    }
  }
}

[class^='MuiListItem-button'],
[class*='MuiListItem-button'] {
  [class^='MuiListItemIcon-root'],
  [class*='MuiListItemIcon-root'] {
    .disable-list & {
      pointer-events: all;
    }
  }
}


[class^='MuiCheckbox-checked'],
[class*='MuiCheckbox-checked'] {
  .disable-list & {
    opacity: 1;
    pointer-events: all;
  }
}

.rc-tree-switcher {
  background-size: 0;
  cursor: pointer !important;
  
  .sidebar-scroll-secondary &,
  .sidebar-category-mer & {
    margin: 0 0 0 -10px !important;
    padding: 5px;
    width: auto !important;
    height: auto !important;
    left: 5px;
    position: relative;
  }

  .material-icons {
    cursor: pointer !important;
    pointer-events: all;
    
    .sidebar-scroll-secondary &,
    .sidebar-category-mer & {
      font-size: 16px;
    }
  }
}

.rc-tree-child-tree {
  display: block;
}

.node-motion {
  transition: all .3s;
  overflow-y: hidden;
}

.node-motion-enter,
.node-motion-leave-active {
  height: 0;
}

.tree-wrapper {
  overflow-x: hidden;
  min-height: 100%;

  .rct-sidebar & {
    margin: 0px -10px;
  }

  .rc-tree {
    min-height: 100%;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }

  & + .form-group-aggregation,
  & + .form-group-field,
  & + .form-group-filter {
    border-top: 1px dashed rgb(199, 199, 199);
  }
}

.rc-tree {
  &-iconEle {
    margin-right: 0 !important;
    width: 20px !important;
  }

  &-title {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 5px;
    transition: color 0.1s ease;

    &:hover {
      text-decoration: none;
      color: $primary !important;
      background-color: transparent;
    }

    .rc-tree-node-selected & {
      background-color: transparent !important;
      border: 0 !important;
    }

    .sidebar-scroll-secondary & {
      padding: 2px 0px;
      font-size: 12px;
    }

    .rc-tree li:hover > .rc-tree-node-content-wrapper & {
      color: $primary !important;
    }
  }

  &-treenode-switcher-close {
    &:not(.no-checkbox) {
      display: flex;
    }

    .rc-tree-checkbox {
      min-width: 17px;
    }
  }

  .MuiModal-root & {
    min-height: 315px;
  }

  li {
    &:first-child,
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      .title-wrapper {
        .title-tooltip {
          bottom: auto;
          top: 100%;
        }
      }
    }

    &:hover {
      .rct-scroll & {
        .rct-sidebar  & {
          background-color: rgba(168, 197, 255, 0);
        }
      }
    }

    ul {
      padding: 0 0 0 10px;
    }

    .rc-tree-node-content-wrapper {
      margin: 0;
      height: auto;
      padding: 6.5px 0px 6.5px 5px;
      align-items: center;
      width: 100%;
      position: relative;

      .scope-dialog & {
        width: auto;
      }
    }
  }

  li {
    .rc-tree-icon_loading {
      position: absolute;
      font-size: 12px;
      background-image: none !important;
      color: rgba(0, 0, 0, 0.54);
      top: 0;
      bottom: 0;
      background-color: #f9f9f9f0 !important;
      right: 0;
      left: -30px;
      z-index: 30;
      display: flex !important;
      align-items: center;
      justify-content: center;
      text-shadow: 1px 1px 4px #f9f9f9;
      width: 100% !important;
      height: 100% !important;
      border-top: 1px dashed #dbdbdb !important;
  
      &:after {
        content: 'Loading...';
        font-size: 10px;
      }
    }

    &.loading {
      background-color: #f9f9f9f0 !important;
      pointer-events: none !important;
    }
  }

  &-treenode {
    .rc-tree & {
      display: flex;
      align-items: center;

      line-height: 1.37;

      padding: 2px 0;
    }

    .rc-tree {
      &-switcher {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  &-node {
    &-selected {
      background-color: transparent;
      border: 0;
    }

    &-content {
      &-wrapper {
        .rc-tree .rc-tree-treenode & {
          height: auto;
        }
      }
    }
  }

  &-switcher {
    height: 20px;
    width: 18px;
    margin: 6px 0;

    .material-icons {
      font-size: 20px;
      max-width: 100%;
      max-height: 100%;

      line-height: 16px;
    }
  }

  .no-switcher {
    .rc-tree-checkbox {
      margin-left: 23px;
    }

    .rc-tree-switcher {
      display: none;
    }

    &.no-checkbox {
      .rc-tree-node-content-wrapper {
        margin: 0;
        padding: 6.5px 0px 6.5px 20px;
        min-height: auto;
        height: auto;
      }
    }
  }
}

.rc-tree-checkbox {
  .rc-tree-treenode & {
    .rc-tree-list & {
      .rc-tree & {
        color: rgba(0, 0, 0, 0.54);
        height: 18px;
        width: 18px;
        border: 2px solid rgba(0, 0, 0, 0.54);
        border-radius: 15%;
        background-image: none;
        margin: 0 5px;
      
        &-checked {
          background-color: #5D92F4 !important;
          border-color: #5D92F4 !important;
      
          &:before {
            content: '';
            display: block;
            width: 25%;
            height: 50%;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            margin: auto;
          }
        }
      
        &-disabled {
          opacity: 0.3;
      
          + span {
            opacity: 0.3;
          }
        }
      }
    }
  }
}

.rc-tree-treenode-switcher-open {
  .rc-tree-switcher_open {
    .material-icons {
      transform: rotate(90deg);
      margin: 0 6px;
    }
  }
}

.selected > .rc-tree-node-content-wrapper > .rc-tree-title {
  background-color: transparent !important;
  border: 0 !important;
  color: $primary !important;
}

.rc-tree-node-content-wrapper-open {
  display: flex !important;
  align-items: center;

  .rc-tree-icon__open rc-tree-icon_loading {
    margin: 0 !important;
  }
}

.rc-tree-node-content-wrapper,
.rc-tree-treenode-switcher-close {
  .rc-tree-title {
    &:hover {
      background-color: transparent;
      border: 0px rgba(0, 0, 0, 0.08) solid;
      color: $primary !important;
    }
  }
}

.rc-tree-node-selected {
  background-color: transparent;
  border: 0;
  opacity: 1;

  .rc-tree-title {
    background-color: transparent;
    border: 0px rgba(0, 0, 0, 0.08) solid;
  }
}

.rc-tree-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: block;
  line-height: 1;
}

.rc-tree-treenode-disabled {
  opacity: 0.3;
}

.rct-with-secondary-title {
  .rc-tree-treenode-disabled {
    opacity: 0.3;
  }

  .rc-tree-checkbox {
    margin-left: 5px !important;
  }
  
  .rc-tree-child-tree {
    position: relative;
  }

  .rc-tree-treenode-switcher-close {
    display: flex;
  }
}

.categories-tree {
  &-popover {
    padding: 20px;
    font-size: 10px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
  }
}