.MuiInputLabel {
  &-outlined {
    background-color: #fff;
  }
}

.MuiFormControl {
  &-root {
    & + .MuiFormControl-root {
      .form & {
        margin-top: 15px;
      }
    }
  }
}
.form {
  &-control {
    display: block;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(70, 77, 105);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
    padding: 0.375rem 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(235, 237, 242);
    border-image: initial;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;

    &-clear {
      box-shadow: transparent 0px 0px 0px 0px !important;
      border-width: 0px;
    }
  }
}

.input-placeholder {
	position: absolute;
	top: -9px;
	left: 12px;
	font-size: 11px;
	color: $danger;
	background-color: $danger-sec;
	padding: 0px 7px;
  border-radius: 2px;
  line-height: 1.5;
}
