.date-time-wrapper {
  will-change: contents;

  font-size: $fz-12;

  @media (min-width: 1400px) {
    font-size: $fz-14;
  }

  .rct-group & {
    line-height: 22px;

    @media (min-width: 1400px) {
      line-height: 27px;
    }
  }
}

.react-daterange-picker {
  &__wrapper {
    .date-picker-secondary & {
      border-width: 0;

      display: flex;
      align-items: center;
    }
  }

  &__inputGroup {
    .date-picker-secondary & {
      min-width: 50px;
      pointer-events: none;
    }

    span {
      opacity: 0.3;
    }
  }

  &__range-divider {
    pointer-events: none;
  }

  &__button {
    .date-picker-secondary & {
      padding: 8px;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 100%;

      transition: background-color 0.2s ease;
    }

    &:hover {
      .date-picker-secondary & {
        will-change: background-color;

        
        background-color: rgba(0, 0, 0, 0.02);
      }
    }

    &:active {
      .date-picker-secondary & {
        will-change: background-color, transition;

        transition: background-color 0.3s ease-in-out;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .MuiSvgIcon-root {
      .date-picker-secondary & {
        font-size: $fz-14;
      }
    }

    .MuiButtonBase-root {
      .date-picker-secondary & {
        padding: 8px;
      }
    }
  }
}

.react-calendar {
  .date-picker-secondary & {
    border-width: 0;

    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;

  }
  
  abbr[title] {
    .date-picker-secondary & {
      font-size: $fz-12;
      text-decoration: none;
    }
  }

  &__navigation {
    .date-picker-secondary & {
      margin-bottom: 0;
    }
  }

  &--selectRange {
    abbr[title] {
      .date-picker-secondary & {
        font-size: $fz-12;
        text-decoration: none;
      }
    }

    .date-picker-secondary & {
      border-width: 0;

      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
      border-radius: 4px;
    }
  }
}
