[class*="skeleton"],
[class^="skeleton"] {
  pointer-events: none !important;
}

.skeleton {
  width: 100%;
  max-width: 20%;
  padding: 10px;
  padding: 15px;

  & + .skeleton {
    .skeleton-block & {
      margin-top: 10px;
    }

    .skeleton-table & {
      margin-top: 2px;
    }
  }

  .skeleton-block & {
    max-width: 100%;
    padding: 0;
    
    .card {
      padding: 12px;
      background-color: #f1f1f1;

      .block,
      .line {
        background-color: #e6e6e6;
      }
    }
  }

  &-message {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(248, 249, 250, 0.3);

    &-inner {
      line-height: 1.4;

      max-width: 400px;
      text-align: center;
    }

    &-secondary {
      font-size: 14px;
      color: #ffffff;
      background-color: #6895f5;
      padding: 6px 12px;
      border-radius: 3px;
    }

    &-sm {
      & > * {
        max-width: 510px;
        text-align: center;
        line-height: 1.8;

      }
    }
  }

  .card {
    &-subtitle,
    &-title {
      margin-bottom: 5px;
    }
  }

  &-row {
    display: flex;
    margin: 0px -10px;
    padding: 10px;

    &.skeleton-block {
      width: 100%;

      margin: 0;
      padding: 0;
    }

    @media (min-width: 1400px) {
      padding: 25px;
    }
  }

  .card {
    width: 100%;
    height: 160px;

    .skeleton-block & {
      height: 90px;
    }

    &-cell {
      .skeleton-block & {
        height: auto;
      }
    }
  }
  
  .block {
    width: 52px;
    height: 52px;
    background-color: #f1f1f1;
    border-radius: 25%;
    background-size: 600px;
    
    &.lg {
      width: 100%;
      height: 100px;
      border-radius: 5px;
    }

    &.xs {
      height: 22px;
      width: 130px;
      border-radius: 5px;
    }
  }
  
  .line {
    width: 100%;
    height: 16px;
    border-radius: 4px;
    background-color: #f1f1f1;
    background-size: 600px;

    & + .block {
      margin-top: 6px;
    }

    &.xs {
      width: 10%;
      height: 30px;

      .skeleton-block & {
        height: 12px;
      }
    }
  }
  
  .block + .line {
    width: 100px;
  }
  
  .line ~ .line {
    background-color: #ddd;
  }
}

.body {
  &-block {
    .skeleton-block & {
      display: flex;
      flex-direction: column;
      flex: 1 0 33%;
    }

    .card-w-auto & {
      .skeleton-block & {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
      }
    }

    &:last-child {
      flex: 0 0 auto;
    }

    & + .body-block {
      margin-left: 10px;
    }
  }
}

.block {
  &.xs {
    .skeleton:nth-child(2) & {
      max-width: 40%;
    }
  }
}

.line {
  .body-block & {
    width: 30px !important;
  }
}

.animation {
  &-circle {
    animation: circle .9s linear infinite;
  }
}

@keyframes circle{
  0%{
    transform: rotate(0deg);
  
  }
  100%{
    transform: rotate(360deg);
  }
}